.table-container {
  margin-top: 30px;
  padding: 20px; }

.react-table {
  width: 100%;
  border-collapse: collapse; }

.react-table th,
.react-table td {
  border: 1px solid #ddd;
  padding: 8px; }

.react-table th {
  background-color: #f2f2f2;
  text-align: center; }

.react-table td {
  text-align: right; }
