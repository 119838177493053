.main-container {
  width: 100%; }

.content {
  margin: 40px;
  margin-top: 100px;
  flex: 1;
  position: relative;
  padding-bottom: 30px; }
  @media (max-width: 768px) {
    .content {
      margin: 16px; } }

body {
  background: #f6f6f6; }

a {
  transition: all 225ms ease; }

.loading-overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  opacity: 0.8;
  background-color: #212121;
  z-index: 2000; }
  .loading-overlay .loading-container {
    display: flex;
    align-items: center; }

.modal-dialogs {
  min-height: 25vh;
  width: 500px;
  margin: 0 auto;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; }

.modal-dialogs .dialog-body {
  padding-top: 25px;
  width: 100%; }

.modal-dialogs .close-button {
  position: absolute !important;
  padding: 10px;
  right: 10px;
  top: 10px; }

.modal-dialogs .submit-button-container {
  margin: 20px 0;
  display: flex;
  justify-content: center; }

.flex {
  display: flex; }

.wrap {
  flex-wrap: wrap; }

.rt-td {
  transform: scale(0.95); }

.rt-th div:first-letter {
  text-transform: capitalize; }

.caroussel-aprovacao {
  width: 500px;
  margin: auto; }

.approvePaper {
  width: 500px;
  height: 500px;
  margin: auto;
  padding-top: 1em;
  padding-left: 2em;
  padding-right: 2em;
  text-align: center;
  position: relative; }

.buttonsGrid {
  position: absolute;
  bottom: 1em;
  width: 470px !important; }

.graph-tooltip {
  border: 1px solid #eee;
  border-radius: 5px;
  font-size: 10px;
  padding: 0 0.25rem 0.25rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  color: black;
  text-align: center;
  margin: 0 auto; }

.graph-tooltip .yValue {
  font-size: 1.25rem;
  color: #528e70; }

.graph-tooltip .yUnits {
  margin-left: 0.25rem; }
